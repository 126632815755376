.container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  min-width: 640px;
  max-width: 1000px;
  text-align: center;
}

#referenceTable {


}

#referenceTable th {
  background-color: #3B417C;
  color: #fafafa;
  padding: 1rem;
}

#referenceTable th:first-child {
  min-width: 200px;
}

#referenceTable td {
  border-bottom: solid 1px #e2e2e2;
}

#referenceTable td:first-child {
  font-weight: bold;
}
#referenceTable td {
  padding: 1rem;
  text-align: center;
  vertical-align: top;
}
#referenceTable td > p {
  margin: 0;
  text-align: left;
}