.header_logo { 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.header_logo > img  {
  max-width: 200px;
}
.header_links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.header_links a {
  margin-left: 1rem;
  margin-right: 1rem;
  outline: none;
  text-decoration: none;
}
.header_links > button {
  margin-left: 1rem;
}
a:link {

}