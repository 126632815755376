.MuiFormControl-root {
    margin: 0.4rem !important;

}

.MuiDialog-paperWidthSm {
    width: 70% !important;
    max-width: 70% !important;
}

.formButton {
    justify-content: center !important;
}

.showPics {
    display: flex;
    flex-direction: column;
}

.hidePics {
    display: none;
}

/*--------------------------------------------------*/
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    z-index: 1;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }