.multiple {
  display: flex;
  justify-content: space-between;
}

.showPhotoLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hidePhotoLinks {
  display: none;
}

.photoCaption {
  display: flex;
  flex-direction: column;
  width: 49.5%;
}

.photoLink {
  display: flex;
  flex-direction: column;
  width: 49.5%;
}

h4 {
  margin-left: 0.5rem;
}

.visible {
  display: block;
}

.hide {
  display: none;
}
.imageGallery {
  display: flex;
  flex-wrap: wrap;
}
.imageThumbnail {
  border-radius: 0.25rem;
  border-color: #c4c4c4;
  border-style: solid;
  border-width: 1px;
  margin: 0.5rem;
}
.imageThumbnail > p {
  padding: 1rem;
}
.imageThumbnail > a > img {
  display: block;
  max-width: 350px;
  max-height: 150px;
  width: auto;
  height: auto;
}
.additionalInformation {
  padding: 1rem;
}
.workReportContainer {
  border: solid 1px #c4c4c4;
  padding: 1rem;
  margin-bottom: 1rem;
  border-raidus: 0.2rem;
}
.workReportContainer > p {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rpt-crew-table {
  display: flex;

  border: solid 1px #c4c4c4;
  border-radius: 0.25rem;
  flex-direction: column;
}
.rpt-crew-table-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #c4c4c4;
  font-weight: bold;
}
.rpt-crew-table-header > div {
  width: 25%;
  padding: 0.75rem;
}
.rpt-crew-table-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.rpt-crew-table-row > div {
  width: 25%;
  padding: 0.75rem;
}
.rpt-crew-table-row-crew {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid 1px #c4c4c4;
}
.rpt-crew-table-row-crew > div {
  width: 25%;
  padding: 0.75rem;
}
.self-center {
  justify-self: center;
}
.errorText {
  color: red;
}

#crewSelection {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  flex: 1;
}

#addCrewButton {
  margin-left: 10px;
  align-self: stretch;
}
.simpleContainer {
  border: solid 1px #c4c4c4;
  padding: 1rem;
  margin-bottom: 1rem;
  border-raidus: 0.2rem;
}

.invoiceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdfInfoHeader{
  margin: 2px;
  margin-bottom: 4px;
}

.invoiceContainer{
  display: flex;
  gap: 10px;
}

.action-container {
  display: flex;
  gap: 1rem;
}
